import React from "react"
import { DateTime } from "luxon"

type MaintenanceNotificationProps = {
	expectedDate?: DateTime
}
const MaintenanceNotification: React.FC<MaintenanceNotificationProps> =
	React.memo(({ expectedDate }) => {
		return (
			<div>
				<h1>Undergoing Maintenance</h1>
				<p>The WHU Serious Game is currently undergoing maintenance.</p>
				{expectedDate && (
					<p>
						We expect the system to be available again at{" "}
						{expectedDate
							.toLocal()
							.toLocaleString(DateTime.DATETIME_FULL)}
					</p>
				)}
			</div>
		)
	})

export default MaintenanceNotification
