import { Route } from "@tanstack/react-location"

import { backendRoute } from "../pages/backend/routes"
import { frontendRoute } from "../pages/frontend/routes"

import { renderAsync, renderAsyncLayout } from "./AsyncRoute"

const public_routes = [
	{
		path: "pub",
		children: [
			{
				element: renderAsyncLayout(
					() => import("../components/layouts/Login")
				),
				children: [
					{
						path: "login",
						element: renderAsync(
							() => import("../pages/public/Login/Login")
						),
					},
					{
						path: "forgot-password",
						element: renderAsync(
							() =>
								import(
									"../pages/public/Password/ForgotPassword"
								)
						),
					},
					{
						path: "reset-password/:token",
						element: renderAsync(
							() =>
								import("../pages/public/Password/ResetPassword")
						),
					},
					{
						path: "change-email/:token",
						element: renderAsync(
							() =>
								import(
									"../pages/public/ChangeEmail/ChangeEmail"
								)
						),
					},
				],
			},
		],
	},
]

const real_routes: Route[] = [
	...public_routes,
	backendRoute,
	{
		element: renderAsyncLayout(
			() => import("../components/layouts/Default/Default")
		),
		children: [
			{
				path: "/",
				element: renderAsync(
					() => import("../pages/frontend/Dashboard/Dashboard")
				),
			},
			{
				path: "account",
				element: renderAsync(
					() => import("../pages/frontend/Account/Account")
				),
			},
			frontendRoute,
		],
	},
]

export const Routes: Route[] = [
	{
		element: renderAsync(() => import("../pages/Authenticated")),
		errorElement: renderAsync(() => import("../pages/AuthenticationError")),
		children: real_routes,
	},
]
