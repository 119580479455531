import { useMemo } from "react"

import Styles from "./Button.module.css"
import Appearances from "./Appearances.module.css"
import Sizes from "./Sizes.module.css"

export type ButtonProps = {
	size?: "small" | "normal" | "large"
	appearance?: "primary" | "primary-light" | "primary-highlight"
	block?: true
	disableInvalid?: true
	shadow?: true
	className?: string
}

export const useButtonProps = <T extends ButtonProps>({
	block,
	size = "normal",
	appearance,
	shadow,
	disableInvalid,
	className,
	...props
}: T) => {
	return useMemo(() => {
		const classes = [Styles.button]

		if (block) {
			classes.push(Styles.btnBlock)
		}

		if (size in Sizes) {
			classes.push(Sizes[size])
		}

		if (appearance && appearance in Appearances) {
			classes.push(Appearances[appearance])
		} else {
			classes.push(Appearances.default)
		}

		if (shadow) {
			classes.push(Styles.btnShadow)
		}

		if (disableInvalid) {
			classes.push("disableInvalid")
		}

		if (className) {
			classes.push(className)
		}

		return {
			...props,
			className: classes.join(" "),
		}
	}, [block, size, appearance, className, disableInvalid, shadow, props])
}
