import { addBreadcrumb, init, setUser } from "@sentry/react"

export const useSentry = import.meta.env.PROD && import.meta.env.VITE_SENTRY_DSN

export const initSentry = () => {
	if (!useSentry) {
		return
	}

	init({
		dsn: import.meta.env.VITE_SENTRY_DSN,
		release: `serious-game@${import.meta.env.VITE_RELEASE_VERSION}`,
		environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
	})
}

export const setSentryUser: typeof setUser = (...args) => {
	if (!useSentry) {
		return
	}

	setUser(...args)
}

export const addSentryBreadcrumb: typeof addBreadcrumb = (...args) => {
	if (!useSentry) {
		return
	}

	addBreadcrumb(...args)
}
