import React, {
	createContext,
	Reducer,
	useCallback,
	useContext,
	useEffect,
	useReducer,
	useState,
} from "react"
import { DateTime } from "luxon"

import { API_ENDPOINT } from "../common/declarations"
import { NotificationTypes } from "../components/global/Notifications/NotificationTypes"

import { useNotificationContext } from "./NotificationContext"

const getNextMaintenance = async (): Promise<DateTime | undefined> => {
	const response = await fetch(`${API_ENDPOINT}/maintenance/next`, {
		credentials: "include",
	})

	if (response.status !== 200) {
		return undefined
	}

	const iso = await response.text()

	return DateTime.fromISO(iso).toLocal()
}

const dateReducer: Reducer<DateTime | undefined, DateTime | undefined> = (
	currentDate,
	newDate
) => {
	if (newDate && !currentDate?.equals(newDate)) {
		return newDate
	}

	return currentDate
}

export const useCreateMaintenanceContext = () => {
	const { addAlert } = useNotificationContext()

	const [maintenance, _setMaintenance] = useState<boolean | DateTime>(false)
	const [nextMaintenance, _setNextMaintenance] = useReducer(
		dateReducer,
		undefined
	)

	const setMaintenance = useCallback((maintenance: boolean | DateTime) => {
		_setMaintenance(maintenance)
	}, [])

	useEffect(() => {
		const alertMaintenance = async () => {
			const date = await getNextMaintenance()

			_setNextMaintenance(date)
		}

		alertMaintenance().then()

		const interval = setInterval(alertMaintenance, 5 * 60 * 1000)

		return () => {
			clearInterval(interval)
		}
	}, [])

	useEffect(() => {
		if (!nextMaintenance) {
			return
		}

		const date = nextMaintenance.toLocaleString(DateTime.DATETIME_FULL)

		addAlert({
			type: NotificationTypes.Info,
			title: "Planned Maintenance",
			content: (
				<>
					System Maintenance will take place at{" "}
					<strong>{date}</strong>.<br />
					Please save your application before then to avoid data loss.
				</>
			),
		})
	}, [nextMaintenance, addAlert])

	return {
		maintenance,
		setMaintenance,
	}
}
export const MaintenanceContext = createContext<ReturnType<
	typeof useCreateMaintenanceContext
> | null>(null)

export const useMaintenanceContext = () => {
	const ctx = useContext(MaintenanceContext)

	if (ctx === null) {
		throw new Error("No MaintenanceContextProvider available.")
	}

	return ctx
}
