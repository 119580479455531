import React, { Suspense } from "react"
import { Outlet } from "@tanstack/react-location"

import LoadingScreen from "./components/global/LoadingScreen/LoadingScreen"
import MaintenanceContextProvider from "./context/MaintenanceContextProvider"
import NotificationContextProvider from "./context/NotificationContextProvider"
import AuthenticationContextProvider from "./context/AuthenticationContextProvider"
import RouterComponent from "./common/RouterComponent"

const App: React.FC = () => {
	return (
		<NotificationContextProvider>
			<Suspense fallback={<LoadingScreen />}>
				<MaintenanceContextProvider>
					<RouterComponent>
						<AuthenticationContextProvider>
							<Outlet />
						</AuthenticationContextProvider>
					</RouterComponent>
				</MaintenanceContextProvider>
			</Suspense>
		</NotificationContextProvider>
	)
}

export default App
