import React from "react"
import ReactDOM from "react-dom/client"
import { Settings } from "luxon"

import "./tailwind.css"
import "./main.css"
import App from "./App"
import { initSentry } from "./common/helper/sentry"

initSentry()
Settings.defaultLocale = "en"

ReactDOM.createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)
