import React from "react"
import PortalComp from "@reach/portal"

type PortalProps = {
	usePortal?: boolean
	children?: React.ReactNode
}
const Portal: React.FC<PortalProps> = ({ usePortal = true, children }) => {
	if (!usePortal) {
		return <>{children}</>
	}

	return <PortalComp>{children}</PortalComp>
}

export default Portal
