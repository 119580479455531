import { Route } from "@tanstack/react-location"

import { renderAsync } from "../../../common/AsyncRoute"
import { apolloClient } from "../../../apollo/client"
import {
	GetCourseQuery,
	GetCourseQueryVariables,
	GetUsersQuery,
	GetUsersQueryVariables,
} from "../../../generated/graphql-operations"
import { GetCourseDocument, GetUsersDocument } from "../../../generated/graphql"

import { manageGroupsRoute } from "./ManageGroups/routes"

export const courseRoute: Route = {
	path: "courses",
	children: [
		{
			path: "/",
			element: renderAsync(() => import("./Index")),
		},
		{
			path: "add",
			element: renderAsync(() => import("./Add")),
		},
		{
			path: ":course_id",
			loader: async ({ params: { course_id } }) => {
				// prepare course
				await apolloClient.query<
					GetCourseQuery,
					GetCourseQueryVariables
				>({
					query: GetCourseDocument,
					variables: {
						id: course_id,
					},
				})

				return {}
			},
			children: [
				{
					path: "edit",
					element: renderAsync(() => import("./Edit")),
				},
				{
					path: "manage-users",
					children: [
						{
							path: "/",
							element: renderAsync(() => import("./ManageUsers")),
							loader: async ({ params: { course_id } }) => {
								await apolloClient.query<
									GetUsersQuery,
									GetUsersQueryVariables
								>({
									query: GetUsersDocument,
									variables: {
										offset: 0,
										limit: 20,
										filter: {
											course_id: {
												eq: course_id,
											},
										},
									},
								})

								return {}
							},
						},
						{
							path: "add",
							element: renderAsync(() => import("./AddUser")),
							loader: async ({ params: { course_id } }) => {
								await apolloClient.query<
									GetUsersQuery,
									GetUsersQueryVariables
								>({
									query: GetUsersDocument,
									variables: {
										offset: 0,
										limit: 20,
										filter: {
											course_id: {
												eq: course_id,
												negate: true,
											},
										},
									},
								})

								return {}
							},
						},
					],
				},
				manageGroupsRoute,
			],
		},
	],
}
