import { API_ENDPOINT } from "../common/declarations"

export const logout = async () => {
	await fetch(`${API_ENDPOINT}/users/logout`, {
		credentials: "include",
		headers: {
			accept: "application/json",
		},
	})
}
