import { Route } from "@tanstack/react-location"

import { renderAsync } from "../../common/AsyncRoute"

import { userRoute } from "./users/routes"
import { courseRoute } from "./courses/routes"
import { dashboardRoute } from "./dashboard/route"

export const backendRoute: Route = {
	path: "/backend",
	element: renderAsync(() => import("../AuthenticatedBackend")),
	children: [dashboardRoute, userRoute, courseRoute],
}
