import { API_ENDPOINT } from "../common/declarations"

export const enum LoginResult {
	ERROR,
	INVALID,
	SUCCESS,
}
export const login = async (email: string, password: string) => {
	const formData = new FormData()
	formData.set("email", email)
	formData.set("password", password)
	formData.set("remember_me", "on")

	try {
		const login = await fetch(`${API_ENDPOINT}/users/login`, {
			method: "post",
			body: formData,
			credentials: "include",
			headers: {
				accept: "application/json",
			},
		})

		if (login.status === 401) {
			return LoginResult.INVALID
		}

		if (login.status !== 200) {
			return LoginResult.ERROR
		}

		const json = await login.json()
		if (json.success === true) {
			return LoginResult.SUCCESS
		}

		return LoginResult.INVALID
	} catch (e) {
		return LoginResult.ERROR
	}
}
