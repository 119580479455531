import { AllTypesMap } from "../generated/graphql-types"

import { readDateTime, readOptionalDateTime } from "./helpers/readDateTime"
import { offsetLimitPagination } from "./helpers/offsetLimitPagination"
import { StrictTypePolicies } from "./type-policy-type-helper"

export const typePolicies: StrictTypePolicies<AllTypesMap> = {
	PorpaginasResult_User: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Course: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_CoursesUser: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_CourseGroup: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Simulation: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	Course: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	User: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	CoursesUser: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	CourseGroup: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Decision: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Period: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Result: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Round: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Run: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	RunUser: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Scenario: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Simulation: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	DecisionConfigurationImpl: {},
	DecisionConfigurationInterface: {},
	DecisionImpl: {},
	DecisionInterface: {},
	DecisionsResult: {},
	DefaultRound: {},
	DefaultRoundConfiguration: {},
	DefaultRun: {},
	DefaultRunConfiguration: {
		fields: {
			endTime: readOptionalDateTime,
		},
	},
	PeriodConfigurationImpl: {},
	PeriodConfigurationInterface: {},
	PeriodImpl: {},
	PeriodInterface: {},
	QuizAnswer: {},
	QuizDecision: {},
	QuizDecisionConfiguration: {},
	QuizQuestionPeriod: {},
	QuizQuestionPeriodConfiguration: {},
	QuizReason: {},
	QuizReasonConfiguration: {},
	QuizResult: {},
	QuizResultConfiguration: {},
	QuizRound: {},
	QuizRoundConfiguration: {},
	QuizRunUser: {},
	QuizRunUserConfiguration: {},
	QuizScenario: {},
	QuizScenarioConfiguration: {},
	QuizSimulation: {},
	QuizSimulationConfiguration: {},
	QuizTextPeriod: {},
	QuizTextPeriodConfiguration: {},
	QuizVideoPeriod: {},
	QuizVideoPeriodConfiguration: {},
	ReasonConfigurationImpl: {},
	ReasonConfigurationInterface: {},
	ReasonImpl: {},
	ReasonInterface: {},
	ResultConfigurationImpl: {},
	ResultConfigurationInterface: {},
	ResultImpl: {},
	ResultInterface: {},
	RoundConfigurationImpl: {},
	RoundConfigurationInterface: {},
	RoundImpl: {},
	RoundInterface: {},
	RunConfigurationImpl: {},
	RunConfigurationInterface: {},
	RunImpl: {},
	RunInterface: {},
	RunUserConfigurationImpl: {},
	RunUserConfigurationInterface: {},
	RunUserImpl: {},
	RunUserInterface: {},
	ScenarioConfigurationImpl: {},
	ScenarioConfigurationInterface: {},
	ScenarioImpl: {},
	ScenarioInterface: {},
	SimulationConfigurationImpl: {},
	SimulationConfigurationInterface: {},
	SimulationImpl: {},
	SimulationInterface: {},
	Mutation: {},
}
