import React from "react"

import Logo from "../Logo"
import { MediumLoadingIndicator } from "../LoadingIndicator/LoadingIndicator"

import Styles from "./LoadingScreen.module.css"

const LoadingScreen: React.FC = () => {
	return (
		<div className={Styles.screen}>
			<div className={Styles.content}>
				<Logo className={Styles.logo} />

				<MediumLoadingIndicator />
			</div>
		</div>
	)
}

export default LoadingScreen
