import { Route } from "@tanstack/react-location"

import { renderAsync } from "../../../common/AsyncRoute"

export const simulationRoute: Route = {
	path: "simulations",
	children: [
		{
			path: ":run_id",
			element: renderAsync(() => import("./SimulationRun")),
		},
	],
}
