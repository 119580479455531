import React from "react"

import { ButtonProps, useButtonProps } from "./useButtonProps"

const Button = React.forwardRef<
	HTMLButtonElement | null,
	JSX.IntrinsicElements["button"] & ButtonProps
>(({ children, ...props }, ref) => {
	const buttonProps = useButtonProps(props)

	return (
		<button {...buttonProps} ref={ref}>
			{children}
		</button>
	)
})

export default Button
