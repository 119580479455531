import React from "react"

import logo from "../../assets/logo.svg"

type LogoProps = {
	className?: string
}
const Logo: React.FC<React.PropsWithChildren<LogoProps>> = ({ className }) => {
	return (
		<img
			className={className}
			src={logo}
			alt="WHU - Otto Beisheim School of Management"
		/>
	)
}

export default Logo
