import { Route } from "@tanstack/react-location"

import { apolloClient } from "../../../../apollo/client"
import { renderAsync } from "../../../../common/AsyncRoute"
import {
	GetCourseGroupQuery,
	GetCourseGroupQueryVariables,
	GetCourseGroupsQuery,
	GetCourseGroupsQueryVariables,
	GetCourseQuery,
	GetCourseQueryVariables,
} from "../../../../generated/graphql-operations"
import {
	GetCourseDocument,
	GetCourseGroupDocument,
	GetCourseGroupsDocument,
} from "../../../../generated/graphql"

export const manageGroupsRoute: Route = {
	path: "manage-groups",
	loader: async ({ params: { course_id } }) => {
		await apolloClient.query<GetCourseQuery, GetCourseQueryVariables>({
			query: GetCourseDocument,
			variables: {
				id: course_id,
			},
		})

		return {}
	},
	children: [
		{
			path: "/",
			element: renderAsync(() => import("./ManageGroups")),
			loader: async ({ params: { course_id } }) => {
				await apolloClient.query<
					GetCourseGroupsQuery,
					GetCourseGroupsQueryVariables
				>({
					query: GetCourseGroupsDocument,
					variables: {
						offset: 0,
						limit: 20,
						filter: {
							course_id: {
								eq: course_id,
							},
						},
					},
				})

				return {}
			},
		},
		{
			path: "add",
			element: renderAsync(() => import("./AddGroup")),
		},
		{
			path: ":group_id",
			loader: async ({ params: { course_id, group_id } }) => {
				await apolloClient.query<
					GetCourseGroupQuery,
					GetCourseGroupQueryVariables
				>({
					query: GetCourseGroupDocument,
					variables: {
						id: group_id,
					},
				})

				return {}
			},
			children: [
				{
					path: "edit",
					element: renderAsync(() => import("./EditGroup")),
				},
				{
					path: "manage-users",
					children: [
						{
							path: "/",
							element: renderAsync(() => import("./GroupUsers")),
						},
						{
							path: "add",
							element: renderAsync(
								() => import("./AddUserToGroup")
							),
						},
					],
				},
			],
		},
	],
}
