import { DateTime } from "luxon"

import { API_ENDPOINT } from "../common/declarations"

export const enum CheckAuthResult {
	ERROR,
	MAINTENANCE,
	AUTHENTICATED,
	UNAUTHENTICATED,
}
export const checkAuth = async () => {
	const response = await fetch(`${API_ENDPOINT}/users/check`, {
		credentials: "include",
		headers: {
			accept: "application/json",
		},
	})

	if (response.status === 503) {
		const date = await response.text()

		return {
			status: CheckAuthResult.MAINTENANCE,
			date: date.length > 0 ? DateTime.fromISO(date) : undefined,
		}
	}

	if (response.status !== 200) {
		return { status: CheckAuthResult.ERROR }
	}

	try {
		const { authenticated } = await response.json()

		return {
			status: authenticated
				? CheckAuthResult.AUTHENTICATED
				: CheckAuthResult.UNAUTHENTICATED,
		}
	} catch (e) {
		return { status: CheckAuthResult.ERROR }
	}
}
