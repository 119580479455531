import React, { useCallback, useState } from "react"
import uniqueId from "lodash/uniqueId"

import Notifications from "../components/global/Notifications/Notifications"
import Portal from "../components/global/Portal/Portal"

import {
	NotificationContext,
	AlertInterface,
	AlertWithId,
} from "./NotificationContext"

const NotificationContextProvider: React.FC<{
	children?: React.ReactNode
}> = ({ children }) => {
	const [alerts, setNotifications] = useState<AlertWithId[]>([])

	const removeAlert = useCallback(
		(id: string) => () => {
			setNotifications((alerts) => alerts.filter((a) => a.id !== id))
		},
		[]
	)

	const addAlert = useCallback((alert: AlertInterface) => {
		setNotifications((alerts) => [
			...alerts,
			{
				id: uniqueId("alert-"),
				...alert,
			},
		])
	}, [])

	const ctx = {
		addAlert,
	}

	return (
		<NotificationContext.Provider value={ctx}>
			{alerts.length > 0 && (
				<Portal>
					<Notifications alerts={alerts} onDismiss={removeAlert} />
				</Portal>
			)}
			{children}
		</NotificationContext.Provider>
	)
}

export default NotificationContextProvider
