import React from "react"
import {
	AsyncElement,
	DefaultGenerics,
	Outlet,
	PartialGenerics,
	UseGeneric,
} from "@tanstack/react-location"

export type RouteProps<TGenerics extends PartialGenerics = DefaultGenerics> = {
	params: UseGeneric<TGenerics, "Params">
}

type _Route<TGenerics extends PartialGenerics = DefaultGenerics> =
	| React.FC
	| React.FC<RouteProps<TGenerics>>

export function renderAsync<
	TGenerics extends PartialGenerics = DefaultGenerics
>(cb: () => Promise<{ default: _Route<TGenerics> }>) {
	let Comp: _Route<TGenerics> | null = null
	const render: AsyncElement<TGenerics> = async (params) => {
		if (!Comp) {
			Comp = (await cb()).default
		}

		return <Comp {...params} />
	}

	return render
}

type LayoutComp = React.FC<{ children: React.ReactNode }>
export function renderAsyncLayout(cb: () => Promise<{ default: LayoutComp }>) {
	let Comp: LayoutComp | null = null
	const render: AsyncElement = async () => {
		if (!Comp) {
			Comp = (await cb()).default
		}

		return (
			<Comp>
				<Outlet />
			</Comp>
		)
	}

	return render
}
