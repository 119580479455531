import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useNavigate, useRouter } from "@tanstack/react-location"

import { apolloClient } from "../apollo/client"
import { checkAuth, CheckAuthResult } from "../api/checkAuth"
import { login, LoginResult } from "../api/login"
import { logout as logoutApi } from "../api/logout"
import LoadingScreen from "../components/global/LoadingScreen/LoadingScreen"

import { AuthenticationContext } from "./AuthenticationContext"
import { useMaintenanceContext } from "./MaintenanceContext"

const AuthenticationContextProvider: React.FC<{
	children?: React.ReactNode
}> = ({ children }) => {
	const [authenticationStatus, setAuthenticationStatus] = useState<
		CheckAuthResult | undefined
	>(undefined)

	const { setMaintenance } = useMaintenanceContext()

	const validatePromise = useRef<Promise<void>>()
	const path = useRef<string>()

	const navigate = useNavigate()
	const { state } = useRouter()

	const currentPath = useMemo(() => {
		return state.location.pathname
	}, [state])

	const logout = useCallback(async () => {
		if (!authenticationStatus) {
			return
		}

		await logoutApi()

		await apolloClient.clearStore()

		setAuthenticationStatus(undefined)
	}, [authenticationStatus])

	const fetchAuthenticated = useCallback(async () => {
		const response = await checkAuth()

		setAuthenticationStatus(response.status)

		if (response.status === CheckAuthResult.MAINTENANCE) {
			setMaintenance(response.date ?? true)
		}
	}, [setMaintenance])

	const authenticate = useCallback(
		async (email: string, token: string) => {
			const status = await login(email, token)

			if (status === LoginResult.INVALID) {
				throw new Error("Your token is invalid.")
			}

			if (status === LoginResult.ERROR) {
				throw new Error("Something went wrong. Please try again.")
			}

			await fetchAuthenticated()
		},
		[fetchAuthenticated]
	)

	useEffect(() => {
		if (authenticationStatus) {
			return
		}

		;(async () => {
			const promise = (async () => {
				try {
					await fetchAuthenticated()
				} catch (ignore) {}
			})()

			validatePromise.current = promise

			await promise

			if (validatePromise.current === promise) {
				validatePromise.current = undefined
			}
		})()
	}, [fetchAuthenticated, authenticationStatus])

	useEffect(() => {
		if (authenticationStatus === CheckAuthResult.AUTHENTICATED) {
			if (currentPath.startsWith("/pub/login")) {
				navigate({ to: path.current ?? "/", replace: true })
			}

			return
		}

		if (
			authenticationStatus === undefined ||
			currentPath.startsWith("/pub")
		) {
			return
		}

		path.current = currentPath

		navigate({
			to: "/pub/login",
			replace: true,
		})
	}, [authenticationStatus, currentPath, navigate])

	const authenticated = useMemo(() => {
		if (authenticationStatus === undefined) {
			return undefined
		}

		return authenticationStatus === CheckAuthResult.AUTHENTICATED
	}, [authenticationStatus])

	const ctx = {
		authenticated,
		authenticationStatus,
		logout,
		authenticate,
		validatePromise,
	}

	return (
		<AuthenticationContext.Provider value={ctx}>
			{ctx.authenticationStatus === undefined && <LoadingScreen />}
			{ctx.authenticationStatus !== undefined && children}
		</AuthenticationContext.Provider>
	)
}

export default AuthenticationContextProvider
