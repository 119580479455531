import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client"

import { API_ENDPOINT } from "../common/declarations"

import { typePolicies } from "./type-policies"
import sentryLink from "./helpers/sentryLink"

const httpLink = new HttpLink({
	uri: `${API_ENDPOINT}/__graphql`,
	credentials: "include",
})

export const apolloClient = new ApolloClient({
	cache: new InMemoryCache({
		typePolicies,
	}),
	link: from([sentryLink, httpLink]),
})
