import React from "react"

import MaintenanceNotification from "../components/global/MaintenanceNotification/MaintenanceNotification"

import {
	MaintenanceContext,
	useCreateMaintenanceContext,
} from "./MaintenanceContext"

const MaintenanceContextProvider: React.FC<{
	children?: React.ReactNode
}> = ({ children }) => {
	const ctx = useCreateMaintenanceContext()

	if (ctx.maintenance) {
		return (
			<>
				<MaintenanceNotification
					expectedDate={
						ctx.maintenance !== true ? ctx.maintenance : undefined
					}
				/>
			</>
		)
	}

	return (
		<MaintenanceContext.Provider value={ctx}>
			{children}
		</MaintenanceContext.Provider>
	)
}

export default MaintenanceContextProvider
