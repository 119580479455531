import { Route } from "@tanstack/react-location"

import { renderAsync } from "../../../common/AsyncRoute"
import { apolloClient } from "../../../apollo/client"
import { GetUserQueryVariables } from "../../../generated/graphql-operations"
import { GetUserDocument } from "../../../generated/graphql"

export const userRoute: Route = {
	path: "users",
	children: [
		{
			path: "/",
			element: renderAsync(() => import("./Index")),
		},
		{
			path: "add",
			element: renderAsync(() => import("./Add")),
		},
		{
			path: "edit/:id",
			element: renderAsync(() => import("./Edit")),
			loader: async ({ params: { id } }) => {
				// prepare user
				await apolloClient.query<GetUserQueryVariables>({
					query: GetUserDocument,
					variables: {
						id: id,
					},
				})

				return {}
			},
		},
	],
}
