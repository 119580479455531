import { createContext, MutableRefObject, useContext } from "react"

import { CheckAuthResult } from "../api/checkAuth"

interface AuthenticationContextInterface {
	authenticated: boolean | undefined
	authenticationStatus: CheckAuthResult | undefined
	authenticate(email: string, token: string): Promise<void>
	logout(): Promise<void>
	validatePromise: MutableRefObject<Promise<void> | undefined>
}
export const AuthenticationContext =
	createContext<AuthenticationContextInterface | null>(null)

export const useAuthenticationContext = (
	suspense = false
): Omit<AuthenticationContextInterface, "validatePromise"> => {
	const ctx = useContext(AuthenticationContext)

	if (ctx === null) {
		throw new Error("No AuthenticationContextProvider available.")
	}

	if (suspense) {
		const timedPromise = new Promise((res) => setTimeout(res, 500))

		const { current } = ctx.validatePromise
		if (current) {
			// wait until current promise is done, but at least 500ms
			// this makes the loading-states smoother
			throw Promise.all([current, timedPromise])
		}

		if (ctx.authenticated === null) {
			throw timedPromise
		}
	}

	return ctx
}
