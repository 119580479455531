import React from "react"
import { Router as BaseRouter } from "@tanstack/react-location"
import { ReactLocationDevtools } from "@tanstack/react-location-devtools"

import LoadingScreen from "../components/global/LoadingScreen/LoadingScreen"

import { Routes } from "./routes"
import { location } from "./router"

const RouterComponent: React.FC<{
	children?: React.ReactNode
}> = ({ children }) => {
	return (
		<BaseRouter
			location={location}
			routes={Routes}
			defaultPendingMs={2000}
			defaultPendingMinMs={500}
			defaultPendingElement={async () => <LoadingScreen />}
		>
			{children}
			{import.meta.env.DEV && import.meta.env.VITE_ROUTER_DEVTOOLS && (
				<ReactLocationDevtools />
			)}
		</BaseRouter>
	)
}

export default RouterComponent
